const SuccessAlert = () => {
    return (
        <div id="suc-alert"
            className="z-3000 fixed mb-3 inline-flex w-fit justify-end items-center
            rounded-lg bg-green-200 px-6 py-5 text-base text-green-700 shadow
            mt-3 mr-3"
            role="alert" style={{visibility: "hidden"}}>
            <span className="mr-2">
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="h-5 w-5">
                        <path
                        fillRule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd"/>
                </svg>
            </span>
            <span id="suc-alert-text"></span>
        </div>
    )
}

export default SuccessAlert;